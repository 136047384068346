import React from "react";
import PropTypes from "prop-types";

import circleCheckmark from "icons/circle-checkmark.svg";
import { LargeSpacer } from "components/ui/VerticalSpacers";

const NoNotifications = ({ children, type }) => {
  return (
    <div
      data-testid="no-notifications"
      style={{ textAlign: `center`, marginTop: `40px` }}
    >
      <div style={{ textAlign: `center` }}>
        <img src={circleCheckmark} width="80" height="80" />
      </div>
      <p style={{ marginTop: `30px` }}>
        You are up to date!
        <br />
        No new {type} to show.
      </p>

      {children}

      <LargeSpacer />
    </div>
  );
};

NoNotifications.displayName = "NoNotifications";
NoNotifications.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string.isRequired,
};

export default NoNotifications;

import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { semesterShape } from "../../propTypes/semesterShape";

export default function EnrolledClassesTable({ semester }) {
  const canViewGrades = useSelector(
    ({ myStatus: { canViewGrades } = {} }) => canViewGrades
  );

  return (
    <>
      <thead>
        <tr>
          <th width="70%">Enrolled</th>
          <th width="15%" style={{ textAlign: `right` }}>
            Units
          </th>
          {canViewGrades && <th width="15%">Grade</th>}
        </tr>
      </thead>
      <tbody>
        {semester.enrolledClasses.map((course) => (
          <Fragment key={`${course.course_id}-${course.cs_course_id}`}>
            {course.sections
              .filter(
                (section) => section.is_primary_section && !section.waitlisted
              )
              .map((section) => (
                <tr key={section.ccn}>
                  <td>
                    {course.course_code}{" "}
                    {course.session_code && `(Session ${course.session_code})`}
                  </td>
                  <td style={{ textAlign: `right` }}>
                    {Number.isInteger(section.units) && (section.units.toFixed(1))}
                  </td>

                  {canViewGrades && (
                    <td>
                      {section.grading.grade ? (
                        section.grading.grade
                      ) : section.grading.midpointGrade ? (
                        <>
                          <i className="fa fa-exclamation-triangle cc-icon-gold"></i>{" "}
                          ?
                        </>
                      ) : section.grading.gradingBasis ? (
                        section.grading.gradingBasis
                      ) : (
                        "&mdash;"
                      )}
                    </td>
                  )}
                </tr>
              ))}
          </Fragment>
        ))}

        <tr>
          <td style={{ textAlign: `right` }}>Total Units:&nbsp;</td>
          <td style={{ textAlign: `right` }}>
            <strong>{semester.enrolledUnits}</strong>
          </td>
          <td></td>
        </tr>
      </tbody>
    </>
  );
}

EnrolledClassesTable.displayName = "EnrolledClassesTable";
EnrolledClassesTable.propTypes = { semester: semesterShape };
